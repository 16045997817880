import React from "react";
import AppIcon from "../assets/Services/AppsIcon.png";
import WebIcon from "../assets/Services/InternetIcon.png";
import SocialMediaIcon from "../assets/Services/SocialMediaIcon.png";
import HRIcon from "../assets/Services/HRIcon.png";
import BrandIcon from "../assets/Services/BrandIcon.png";
import CreativeContent from "../assets/Services/CreativeContent.png";
import TechnicalDevelopment from "../assets/Services/TechnicalDevelopment.png";
import BusinessManagement from "../assets/Services/BusinessManagement.webp";
import SaleCustomerRelations from "../assets/Services/SalesCustomerRelations.png";
import SpecializedServices from "../assets/Services/SpecialServices.png";

import lottie from "lottie-web";
import marketingAnimationData from "../assets/animations/marketing.json";
import creativeAnimationData from "../assets/animations/Creative.json";
import developmentAnimationData from "../assets/animations/Development.json";
import businessAnimationData from "../assets/animations/Business.json";
import salesAnimationData from "../assets/animations/Sales.json";
import specialAnimationData from "../assets/animations/Special.json";

import { Link } from "react-router-dom";

const Services = () => {
  const marketingContainerRef = React.useRef();
  const creativeContainerRef = React.useRef();
  const developmentContainerRef = React.useRef();
  const businessContainerRef = React.useRef();
  const salesContainerRef = React.useRef();
  const specialContainerRef = React.useRef();

  const services = [
    {
      title: "Digital Presence & Marketing",
      description: "Lorem ipsum dolor sit amet consectetur ",
      icon: SocialMediaIcon,
      indicator: "Marketing",
      reference: marketingContainerRef,
    },
    {
      title: "Creative Content & Production",
      description: "Lorem ipsum dolor sit amet consectetur ",
      icon: CreativeContent,
      indicator: "Creative",
      reference: creativeContainerRef,
    },
    {
      title: "Technical Development & Innovation",
      description: "Lorem ipsum dolor sit amet consectetur ",
      icon: TechnicalDevelopment,
      indicator: "Development",
      reference: developmentContainerRef,
    },
    {
      title: "Business Development & Management",
      description: "Lorem ipsum dolor sit amet consectetur ",
      icon: BusinessManagement,
      indicator: "Business",
      reference: businessContainerRef,
    },
    {
      title: "Sales and Customer Relations",
      description: "Lorem ipsum dolor sit amet consectetur ",
      icon: SaleCustomerRelations,
      indicator: "Sales",
      reference: salesContainerRef,
    },
    {
      title: "Specialized Services",
      description: "Lorem ipsum dolor sit amet consectetur ",
      icon: SpecializedServices,
      indicator: "Specialized",
      reference: specialContainerRef,
    },
  ];

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: marketingContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: marketingAnimationData, // The JSON animation data
    });

    const anim2 = lottie.loadAnimation({
      container: creativeContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: creativeAnimationData, // The JSON animation data
    });

    const anim3 = lottie.loadAnimation({
      container: developmentContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: developmentAnimationData, // The JSON animation data
    });

    const anim4 = lottie.loadAnimation({
      container: businessContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: businessAnimationData, // The JSON animation data
    });

    const anim5 = lottie.loadAnimation({
      container: salesContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: salesAnimationData, // The JSON animation data
    });

    const anim6 = lottie.loadAnimation({
      container: specialContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: specialAnimationData, // The JSON animation data
    });

    return () => {
      anim.destroy(); // Clean up animation when component unmounts
      anim2.destroy();
      anim3.destroy();
      anim4.destroy();
      anim5.destroy();
      anim6.destroy();
    };
  }, []);

  return (
    <div className="w-[60%] mx-auto h-[100%] flex flex-wrap relative">
      {services.map((service, index) => (
        <Link
          to={`/services/${encodeURIComponent(service.indicator)}`}
          key={index}
          className="flex flex-col  items-center w-[30%] max-h-[50%] m-2 cursor-pointer hover:scale-125  transition-all duration-1000 ease-in-out relative"
        >
          <div
            className="h-[50%] mt-1 object-contain"
            alt=""
            ref={service.reference}
          ></div>
          <p className="text-[#ffffff] text-md text-center">{service.title}</p>
        </Link>
      ))}
    </div>
  );
};

export default Services;
