import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Logo from "../assets/BigLogo.png";
import video from "../assets/TheArkVideo2.mp4";
import video2 from "../assets/joelle ark video.mp4";
import SideImage from "../assets/sideArkImage.png";

const AboutPage = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [videoAniamtion, setVideoAnimation] = useState(false);
  const [temp, settemp] = useState(false);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);

    if (position > 0 && !scrolled) {
      setScrolled(true);
    } else if (position === 0 && scrolled) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setVideoAnimation(true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar inHome={false} setNavOpened={settemp} />
      <div className="flex flex-col md:flex-row min-h-[82vh] w-full  relative md:mt-36">
        <div
          className={`flex flex-col  h-[200px] w-full md:h-[85vh] md:w-[15%] items-center justify-center transition-all ease-in-out duration-1000  relative mt-[-50px] md:mt-0 ${
            videoAniamtion ? "bg-[#000000] " : "bg-transparent"
          } `}
        >
          <img
            src={SideImage}
            className="absolute bottom-0 left-0 h-[100%] object-contain opacity-0 md:opacity-100"
            alt=""
          />
          <p className="text-white text-3xl md:hidden">Story Behind The ARK</p>
        </div>
        <div
          className={`flex flex-col md:pl-48 pl-5 md:mt-0 mt-[75%]  justify-center h-full md:w-[85%] w-[100%]  `}
        >
          <p
            className={`text-3xl mb-2 transition-all ease-in-out duration-1000 hidden md:flex ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            Story Behind The ARK
          </p>
          <p
            className={`w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            The ARK is a joint stock company based in{" "}
            <span className="font-bold text-lg">Beirut, Lebanon</span>,
            specialized in{" "}
            <span className="font-bold text-lg">Project Management</span> and{" "}
            <span className="font-bold text-lg">Human Development</span> across
            educational, cultural, and economic dimensions, with a specific
            emphasis on the{" "}
            <span className="font-bold text-lg">Digital Industries</span>. Our
            organization is deeply committed to nurturing human potential and
            managing human resources effectively.
          </p>
          <br />
          <p
            className={`w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            At The ARK, our primary goal is to create{" "}
            <span className="font-bold text-lg">
              Sustainable Economic Growth
            </span>{" "}
            while generating{" "}
            <span className="font-bold text-lg">Business Opportunities</span>{" "}
            for our <span className="font-bold text-lg">Diverse Network</span>.
            We are dedicated to preserving and enhancing Lebanon's rich cultural
            identity while providing a secure and nurturing space for our
            creative community to come together, express, and draw inspiration.
            Our hubs serve as spaces where individuals can engage in idea
            exchange during networking events, gain valuable insights from
            workshops and seminars, and forge meaningful connections while
            working on projects from around the world.
          </p>
          <br />
          <p
            className={`w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            By becoming a part of our freelancers pool, you'll benefit from our
            dedicated market hunters who navigate the digital job markets on
            your behalf, allowing you to focus on honing your skills and
            continuously growing within a supportive community.
          </p>
          <br />
          <p
            className={`w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            The ARK believes in the harmony of{" "}
            <span className="font-bold text-lg">Four Fundamental Pillars</span>{" "}
            for any thriving community:
          </p>
          <p
            className={`w-[90%] ml-[5%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            <span className="font-bold text-lg">Will:</span> A population with a
            strong desire for progress and a visionary outlook.
            <br />
            <span className="font-bold text-lg">Resources:</span> Efficient
            utilization of available resources to create a sustainable
            ecosystem. <br />
            <span className="font-bold text-lg">Culture:</span> A healthy and
            shared set of values that form the foundation for growth. <br />
            <span className="font-bold text-lg">Network:</span> Well-maintained
            connections to other communities, resources, and cultures that open
            doors to opportunities and cooperation. <br />
          </p>
          <br />
          <p
            className={`w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            When these four pillars move in{" "}
            <span className="font-bold text-lg">Harmony</span>, a community can
            withstand the test of time and flourish into the future.
          </p>
          <br />
          <p
            className={`w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            Join The ARK now and become a part of an ecosystem that{" "}
            <span className="font-bold text-lg">
              Connects, Creates, and Conquers.
            </span>
          </p>
        </div>
        <div
          className={`absolute md:w-[20%] w-[90%] md:h-[60%] h-[400px] bg-[#e0e0e0] md:left-[4%] left-[5%] md:top-[20%] top-[7%] rounded-xl shadow-2xl transition-all ease-in-out duration-1000 ring-[#FFFFFF] ${
            videoAniamtion ? "scale-100" : "scale-0 rotate-90"
          } `}
        >
          <video
            className="absolute object-cover w-full h-full rounded-xl"
            src={video}
            autoPlay={false}
            loop
            muted={false}
            controls
            type="video/mp4"
          ></video>
        </div>
      </div>
      <div className="flex flex-col md:flex-row h-auto md:h-[82vh] w-full  relative mt-[20vh] pt-60 md:pt-0">
        <div
          className={`flex flex-col pl-10  md:mt-0 md:pl-20 justify-center h-full w-[100%] md:w-[37.5%] bg-white `}
        >
          <p
            className={`text-4xl mb-4 transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            Mission
          </p>
          <p
            className={`md:w-[60%] w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            Our <span className="font-bold text-lg">Mission</span> is to provide
            our network with the essential infrastructure, connections, and
            economic opportunities required for personal and professional
            growth.
          </p>
        </div>
        <div
          className={`flex flex-col pl-10 justify-center h-full w-[100%] md:w-[37.5%] bg-white mt-16 mb-5 md:mb-0 md:mt-0`}
        >
          <p
            className={`text-4xl mb-4 transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            Vision
          </p>
          <p
            className={`md:w-[50%] w-[90%] transition-all ease-in-out duration-1000 ${
              videoAniamtion ? "opacity-100" : "opacity-0"
            }`}
          >
            Our <span className="font-bold text-lg">Vision</span> is to
            establish a decentralized network of physical and on-chain hubs that
            unite forward-thinking individuals from around the world in a haven
            for cultural enrichment and business innovation in a world of
            digital and global markets.
          </p>
        </div>
        <div
          className={`hidden md:flex h-full w-[25%] items-center justify-center transition-all ease-in-out duration-1000 relative   ${
            videoAniamtion ? "bg-[#000000] " : "bg-transparent"
          }`}
        >
          <img
            src={SideImage}
            className="absolute bottom-0 right-0  h-[100%] object-contain   "
            alt=""
          />
        </div>
        <div
          className={`absolute md:w-[20%] w-[90%] md:h-[60%] h-[350px] bg-[#e0e0e0] md:right-[15%] right-[5%] md:top-[20%] top-[-18%] rounded-xl shadow-2xl transition-all ease-in-out duration-1000 ring-[#FFFFFF] ${
            scrolled ? "scale-100" : "scale-0 rotate-90"
          } `}
        >
          <video
            className="absolute object-cover w-full h-full rounded-xl"
            src={video2}
            autoPlay={false}
            loop
            muted={false}
            controls
            type="video/mp4"
          ></video>
        </div>
      </div>
      {/* <div className="h-[80vh] w-full flex flex-col">
        <div className="w-full h-[50%]  flex bg-[#e0e0e0] relative">
          <img
            src={Logo}
            alt=""
            className={
              scrolled
                ? "w-[25%] h-[90%] object-contain m-4 absolute left-0 top-0 transition-all ease-in-out duration-1000"
                : "w-[25%] h-[90%] object-contain m-4 absolute left-[-100%]  top-0 transition-all ease-in-out duration-1000"
            }
          />
          <div
            className={
              scrolled
                ? "w-[70%] h-full absolute right-0 top-0 flex flex-col p-32  justify-center bg-[#eeeeee] transition-all ease-in-out duration-1000"
                : "w-[70%] h-full absolute right-[-100%] top-0 flex flex-col p-32  justify-center bg-[#eeeeee] transition-all ease-in-out duration-1000"
            }
          >
            <p>MISSION</p>
            <p className="text-5xl mt-2">
              Lorem ipsum dolor sit amet consectetur. Quis sit consectetur
              viverra amet.
            </p>
          </div>
        </div>
        <div className="w-full h-[50%]  flex bg-[#e0e0e0] relative">
          <div
            className={
              scrolled
                ? "w-[70%] h-full absolute left-0 top-0 flex flex-col p-32  justify-center bg-[#000000] transition-all ease-in-out duration-1000"
                : "w-[70%] h-full absolute left-[-100%] top-0 flex flex-col p-32  justify-center bg-[#000000] transition-all ease-in-out duration-1000"
            }
          >
            <p className="text-white">VISION</p>
            <p className="text-5xl mt-2 text-white">
              Lorem ipsum dolor sit amet consectetur. Quis sit consectetur
              viverra amet.
            </p>
          </div>
          <img
            src={Logo}
            alt=""
            className={
              scrolled
                ? "w-[25%] h-[90%] object-contain m-4 absolute right-0 top-0 transition-all ease-in-out duration-1000"
                : "w-[25%] h-[90%] object-contain m-4 absolute right-[-100%]  top-0 transition-all ease-in-out duration-1000"
            }
          />
        </div>
      </div> */}
    </div>
  );
};

export default AboutPage;
