import React, { useState } from "react";
import Navbar from "../components/Navbar";
import tempImage from "../assets/uni id.jpeg.png";
import tempImage2 from "../assets/tempImage.png";
import bannerImage from "../assets/banner.jpeg";
import Mohamad from "../assets/TeamImages/Faki.png";
import Ali from "../assets/TeamImages/Ali.png";
import Celine from "../assets/TeamImages/Celine.png";
import Joelle from "../assets/TeamImages/Joel.png";
import Melissa from "../assets/TeamImages/Melissa.png";
import Ahmad from "../assets/TeamImages/Ahmad.png";
import Sarah from "../assets/TeamImages/Sarah.png";
import Oscar from "../assets/TeamImages/Oscar.png";
import Ivita from "../assets/TeamImages/Ivita.png";

const TeamPage = () => {
  const [show, setShow] = useState(false);
  const [tempData, setTempData] = useState({});

  const teamData = [
    {
      fullName: "Ali Baroudy",
      position: "Chairman & CEO",
      responsibilities:
        "I bring leadership and balance to The ARK. My main role is to bring in the business, grow the network, and make sure everyone on the team is motivated and pushing toward excellence.",
      hobbies:
        "I'm passionate about the human connection and everything that can be achieved through communication and a growth mindset, so I spend most of my time connecting with people and learning all the different cultures out there.",
      horoscope: "Virgo",
      horoscopeIcon: "♍",
      nickname: "Al-Ponze",
      professionalGoals:
        "To build one of the region's most advanced and efficient business networks filled with innovation and pioneer projects.",
      favoriteThingToDoAtWork:
        "I love putting together new business plans along with the rest of the team and coming up with the right strategies to bring them all to life.",
      leastFavoriteThingToDoAtWork: "Repeating myself",
      notAtWork:
        "I'm either making music, spending time with my dogs, or planning my next trip.",
      funFact: "I love cooking!",
      image: Ali,
    },

    {
      fullName: "Melissa El Feghali",
      position: "Chief Operations Officer",
      responsibilities:
        "Manage the development and user experience team. Make sure the projects' milestones and tasks are properly worked on and met. Coordinate and organize necessary processes in the product and service process.",
      hobbies:
        "Traveling, camping, reading, learning new languages, and meeting people from different cultures.",
      horoscope: "Sagittarius",
      horoscopeIcon: "♐",
      nickname: "Mell - Mallouss",
      professionalGoals:
        "Build effective solutions for people to drive impactful change in societies. Help grow a network of leaders, with a focus on youth integration, that will ensure the sustainable development of the country, region, and the world.",
      favoriteThingToDoAtWork:
        "Create safe, organized and effective spaces for the team members to work in and grow.",
      leastFavoriteThingToDoAtWork:
        "Get stuck in a routine of dealing with secondary things.",
      notAtWork:
        "I go on road trips, spontaneous adventures, travel trips, meet new people, and spend time with friends.",
      funFact: "I've walked with lions",
      image: Melissa,
    },
    {
      fullName: "Mohamad Al Faki",
      position: "Chief Technology Officer",
      responsibilities:
        "Guiding tech vision, leading development for The ARK, creating exceptional software solutions.",
      hobbies:
        "Passionate about coding and development, viewing it as a canvas for shaping the future.",
      horoscope: "Gemini",
      horoscopeIcon: "♊",
      nickname: "Roomba",
      professionalGoals:
        "Lead a team to develop cutting-edge solutions, leveraging tech for The ARK's success.",
      favoriteThingToDoAtWork:
        "Designing intricate software solutions, showcasing expertise and creativity.",
      leastFavoriteThingToDoAtWork:
        "Dealing with legacy code and outdated tech, prefers innovative projects.",
      notAtWork:
        "Enjoying quality time with friends, balancing work and friendship.",
      funFact: "A gaming enthusiast!",
      image: Mohamad,
    },

    {
      fullName: "Sara Rahmoun",
      position: "Marketing Director",
      responsibilities:
        "I lead strategic initiatives to drive brand visibility and growth through innovative marketing campaigns.",
      hobbies:
        "Piano playing, sunset chasing, camping, stargazing, hiking, reading, writing, contemplating life, getting out of breakdowns (I also gym sometimes).",
      horoscope: "Virgo",
      horoscopeIcon: "♍",
      nickname: "Mango",
      professionalGoals:
        "Become a CEO in my own company, I see myself thriving in the world of FNB.",
      favoriteThingToDoAtWork:
        "Crafting compelling creative marketing campaigns that captivate audiences and drive results.",
      leastFavoriteThingToDoAtWork:
        "Watching sunsets, reading, or hanging out with my friends on some random stairs.",
      notAtWork: "I make great brownie cookies",
      funFact: "I've walked with lions",
      image: Sarah,
    },
    {
      fullName: "Evita Bouhabib",
      position: "Art Director",
      responsibilities:
        "Branding and graphic related work, illustration, animation.",
      hobbies: "Diving, Chief of my scouts unit, painting, martial arts.",
      horoscope: "Virgo",
      horoscopeIcon: "♍",
      nickname: "Evi",
      professionalGoals: "To become a professional 3D animator",
      favoriteThingToDoAtWork: "Illustrations",
      leastFavoriteThingToDoAtWork: "Driving to work",
      notAtWork: "I'm at the scouts",
      funFact: "I'm not fun at all",
      image: Ivita,
    },

    {
      fullName: "Ahmad Ramadan",
      position: "Director of Research and Data Management",
      responsibilities:
        "I revolve around guiding and overseeing research endeavors, maintaining high standards to ensure the relevance and impact of our work, and keeping our research updated, innovative, and effective.",
      hobbies:
        "To stay engaged and balanced, Online gaming for excitement, and reading books satisfies my thirst for knowledge. I also enjoy watching documentaries and to recharge, I spend time in nature, simply enjoying the outdoors.",
      horoscope: "Pisces",
      horoscopeIcon: "♓",
      nickname: "Fabz",
      professionalGoals:
        "Rooted for optimizing operations and driving efficiency. I aspire to continue enhancing processes, fostering innovation, and delivering tangible results.",
      favoriteThingToDoAtWork: "Brainstorming and putting ideas on the table",
      leastFavoriteThingToDoAtWork: "Recurring tasks",
      image: Ahmad,
      notAtWork:
        "Looking for my next new interest to dive into, enjoying some art, visuals, and music, a few COD Warzone matches are a plus.",
      funFact: "I have my very own parallel universe, in my imagination.",
    },

    {
      fullName: "Oscar",
      position: "Chief Happiness Officer",
      responsibilities:
        "I make sure everyone is always smiling and giving me belly rubs",
      hobbies: "Belly rubs, playing fetch, chasing cats, and lots of barking",
      horoscope: "Leo",
      horoscopeIcon: "♌",
      nickname: "Osco",
      professionalGoals:
        "To develop AI technology that can translate what dogs say into human words",
      favoriteThingToDoAtWork: "Bark at strangers coming to The ARK",
      leastFavoriteThingToDoAtWork: "When I'm asked to be quiet",
      notAtWork: "I'm off on an adventure or taking a nap on my warm bed",
      funFact: "I don't share any of my toys",
      image: Oscar,
    },
  ];

  const [temp, settemp] = useState(false);

  return (
    <div>
      <Navbar inHome={false} setNavOpened={settemp} />
      <div className="flex flex-wrap mt-6 md:px-40 px-4 bg-white pt-32">
        {teamData.map((team, index) => (
          <div
            key={index}
            className="w-1/2 md:w-1/4 p-2 cursor-pointer"
            onClick={() => {
              setShow(true);
              setTempData(team);
            }}
          >
            <div className=" rounded-md  relative flex flex-col items-center justify-center">
              <img
                src={team.image}
                alt=""
                className="w-[150px] h-[150px] object-contain "
              />
              <div className="p-2  w-full bg-[#ffffff63] flex flex-col items-center justify-center">
                <h2 className="font-bold text-lg text-center">
                  {team.fullName}
                </h2>
                <p className="text-gray-500 text-sm text-center">
                  {team.position}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`absolute top-0 right-0 md:h-screen w-screen md:overflow-y-hidden overflow-y-scroll py-8 items-center justify-center flex transition-all duration-[850ms] ease-in-out  ${
          show ? "z-[50] backdrop-blur-sm" : "z-[-1]"
        }`}
        onClick={() => setShow(false)}
      >
        <div
          className={`w-[90%]  md:h-[70%] md:overflow-y-hidden overflow-y-scroll bg-[#ffffff] transition-all duration-[750ms] ease-in-out z-50 ${
            show ? "scale-100 rounded-md" : "scale-0 rounded-[50%]"
          }`}
        >
          <div className="h-[35%] w-full  rounded-t-md relative">
            <img
              src={bannerImage}
              alt="tempImage"
              className={`w-full h-[50%] object-contain bg-[#171717]  transition-all duration-[500ms] ${
                show ? "rounded-t-md" : "rounded-t-[50%]"
              }`}
            />
            <img
              src={tempData.image}
              alt="tempImage2"
              className="md:h-[150px] md:w-[150px] h-[100px] w-[100px] absolute md:bottom-0 bottom-[-15px] md:left-10 left-2 rounded-[30%] object-contain"
            />
            <div className="w-full h-[50%] flex flex-col">
              <p className="md:ml-[18%] ml-[38%] font-bold md:text-5xl text-xl my-auto flex  ">
                {tempData.fullName}
              </p>
              <p className="md:ml-[18%] ml-[38%] flex md:mt-[-20px] text-[#6a7868]">
                {tempData.position}
              </p>
            </div>
          </div>
          <div className="h-[65%] w-full  flex md:flex-row flex-col px-4 pt-4">
            <div className="h-full md:w-[33%] w-[100%] px-2 ">
              <p className="font-bold text-xl text-[#6a7868]">
                Responsibilities
              </p>
              <p className="font-medium text-md">{tempData.responsibilities}</p>
              <p className="font-bold text-xl mt-4 text-[#6a7868]">Nickname</p>
              <p className="font-medium text-md">{tempData.nickname}</p>
              <p className="font-bold text-xl mt-4 text-[#6a7868]">Horoscope</p>
              <p className="font-medium text-md first-child:text-xl ">
                {tempData.horoscopeIcon} {tempData.horoscope}
              </p>
              <p className="font-bold mt-4 text-xl text-[#6a7868]">
                Fun Fact About Me
              </p>
              <p className="font-medium text-md mb-[20px] md:mb-0">
                {tempData.funFact}
              </p>
            </div>
            <div className="h-full md:w-[33%] w-[100%] px-2 ">
              <p className="font-bold text-xl text-[#6a7868]">Hobbies</p>
              <p className="font-medium text-md">{tempData.hobbies}</p>
              <p className="font-bold mt-4 text-xl text-[#6a7868] ">
                Professional Goals
              </p>
              <p className="font-medium text-md  mb-[20px] md:mb-0">
                {tempData.professionalGoals}
              </p>
            </div>
            <div className="h-full md:w-[33%] w-[100%] px-2 ">
              <p className="font-bold text-xl text-[#6a7868]">
                Favorite Thing To Do{" "}
              </p>
              <p className="font-medium text-md">
                {tempData.favoriteThingToDoAtWork}
              </p>
              <p className="font-bold mt-4 text-xl text-[#6a7868]">
                Least Favorite Thing To Do{" "}
              </p>
              <p className="font-medium text-md  mb-[20px] md:mb-0">
                {tempData.leastFavoriteThingToDoAtWork}
              </p>
              <p className="font-bold mt-4 text-xl text-[#6a7868]">
                When I'm Not At Work
              </p>
              <p className="font-medium text-md  mb-[20px] md:mb-0">
                {tempData.notAtWork}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
