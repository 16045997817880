import React, { useState, useEffect } from "react";
import LogoBig from "../assets/hope-everything-right-4k-5l.jpg";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import contactUsAnimationData from "../assets/animations/ContactUs.json";
import lottie from "lottie-web";

const ContactUs = () => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const contactUsContainerRef = React.useRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: contactUsContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: contactUsAnimationData, // The JSON animation data
    });

    return () => {
      anim.destroy(); // Clean up animation when component unmounts
    };
  }, []);

  const handleSubmit = () => {
    if (
      first === "" ||
      last === "" ||
      email === "" ||
      number === "" ||
      message === ""
    ) {
      alert("Please fill all the fields");
      return;
    } else {
      db.collection("ContactUs")
        .add({
          first: first,
          last: last,
          email: email,
          number: number,
          message: message,
        })
        .then(() => {
          alert("Thank you for contacting us, we will get back to you soon");
          setFirst("");
          setLast("");
          setEmail("");
          setNumber("");
          setMessage("");
        });
    }
  };

  return (
    <div className="w-full h-full flex px-48 ">
      <div className="flex flex-col  w-[40%] h-[100%] justify-center px-4">
        <div className="flex flex-col mt-4">
          <input
            style={{ outline: "none" }}
            className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
            value={first}
            onChange={(e) => setFirst(e.target.value)}
            placeholder="First Name"
          />
        </div>

        <div className="flex flex-col mt-4">
          <input
            style={{ outline: "none" }}
            className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
            value={last}
            onChange={(e) => setLast(e.target.value)}
            placeholder="Last Name"
          />

          <div className="flex flex-col mt-4">
            <input
              style={{ outline: "none" }}
              className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />

            <div className="flex flex-col mt-4">
              <input
                style={{ outline: "none" }}
                className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Phone Number"
              />

              <div className="flex flex-col mt-4">
                <textarea
                  style={{ outline: "none" }}
                  className="rounded-sm p-0 h-auto bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff] border-x-0 border-t-0"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                />
              </div>
              <div className="flex flex-row w-full justify-end">
                <button
                  className="mt-8 bg-[transparent]  py-2 rounded-sm text-[#ffffff]"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-[90%] w-[60%]" ref={contactUsContainerRef}></div>
    </div>
  );
};

export default ContactUs;
