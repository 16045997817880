import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addData: (state, action) => {
      const data = {
        id: nanoid(),
        text: action.payload,
      };
      state.data.push(data);
    },
    removeData: (state, action) => {
      state.data = state.data.filter((data) => data.id !== action.payload);
    },
  },
});

export const { addData, removeData } = dataSlice.actions;

export default dataSlice.reducer;
