import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Welcome from "./pages/Welcome";
import ServicesPage from "./pages/ServicesPage";
import AboutPage from "./pages/AboutPage";
import BlogsPage from "./pages/BlogsPage";
import PortfolioPage from "./pages/PorfolioPage";
import TeamPage from "./pages/TeamPage";

import lottie from "lottie-web";
import animationData from "./assets/animations/AboutUsOut.json";

function App() {
  const animationContainerRef = React.useRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainerRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: animationData, // The JSON animation data
    });

    return () => {
      anim.destroy(); // Clean up animation when component unmounts
    };
  }, []);

  return (
    <div className="App overflow-hidden ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/services/:data" element={<ServicesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blogs/:document/:collection" element={<BlogsPage />} />
          <Route path="/portfolio/:type" element={<PortfolioPage />} />
          <Route path="/team" element={<TeamPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
