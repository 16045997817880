import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC2I4gYwKCH2Vc7xADhqK-7VkZTmpVfBfI",
  authDomain: "thearkwebsite-2d0e9.firebaseapp.com",
  projectId: "thearkwebsite-2d0e9",
  storageBucket: "thearkwebsite-2d0e9.appspot.com",
  messagingSenderId: "715544829724",
  appId: "1:715544829724:web:c5d32c0c42a672287864d9",
  measurementId: "G-CGCGJXTRQB",
};

let app = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
db.settings({ experimentalForceLongPolling: true });

export { auth, db, storage, firebase };
