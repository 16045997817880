import React from "react";
import LogoBig from "../assets/BigLogo.png";
import { Link } from "react-router-dom";
import bg from "../assets/AboutComponentShade.png";
const Aboutus = () => {
  return (
    <div className="w-full h-full flex  overflow-hidden  ">
      <div className="flex h-[90%] w-[60%]  flex-col  justify-center pl-48 pr-36 text-white">
        <p
          style={{
            color: "#FFF",
            fontSize: "3.03369rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "102%" /* 3.09431rem */,
            textTransform: "uppercase",
            marginBottom: "2.5rem",
          }}
        >
          Who We Are
        </p>
        The ARK is a joint stock company based in Beirut, Lebanon, specialized
        in project management and human development across educational,
        cultural, and economic dimensions, with a specific emphasis on the
        digital industries. Our organization is deeply committed to nurturing
        human potential and managing human resources effectively...
        <Link
          className="w-[20%]  text-white mt-12 p-2 rounded-sm"
          to={"/about"}
        >
          Read More
        </Link>
      </div>
      <img
        src={bg}
        alt="bg"
        className="absolute w-[50%] right-[-10%] bottom-[-5%] object-contain opacity-50"
      />
    </div>
  );
};

export default Aboutus;
