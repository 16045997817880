import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { Link, useParams } from "react-router-dom";
import tempImage from "../assets/tempImage.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import {
  BsGooglePlay,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { SiGooglemybusiness } from "react-icons/si";
import { FaAppStoreIos, FaFacebookSquare } from "react-icons/fa";

// Import Swiper styles
import "swiper/css";
import { db } from "../firebase";

const PorfolioPage = () => {
  let { type } = useParams();

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [temp, setTemp] = useState({});

  useEffect(() => {
    db.collection("Portfolio")
      .where("type", "==", type)
      .onSnapshot((snapshot) => {
        setData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      });
    setShow(false);
  }, [type]);

  const [temp2, settemp2] = useState(false);

  return (
    <>
      {!show && (
        <div>
          <Navbar inHome={false} setNavOpened={settemp2} />{" "}
          <p className="mx-4 font-bold text-lg mt-40">{type}</p>
          <div className="flex flex-wrap mt-3 md:px-36 px-4">
            {data?.map((blog, index) => (
              <div
                key={index}
                className="w-full md:w-1/4 p-4 scale-100  cursor-pointer "
                onClick={() => {
                  setShow(true);
                  setTemp(blog);
                }}
              >
                <div className="bg-white rounded-lg shadow-lg relative">
                  <img
                    src={blog.coverImage}
                    alt=""
                    className="w-full h-52 object-cover rounded-t-lg"
                  />
                  <div className=" bottom-[-100%] h-8  w-full z-10 flex items-center justify-center px-4 font-bold text-center  mx-auto">
                    {blog.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {show && (
        <div>
          <Navbar inHome={false} setNavOpened={settemp2} />
          <button
            onClick={() => setShow(false)}
            className="ml-4 cursor-pointer  text-[#000000] border-2 border-[#000000] text-center py-2 px-4 rounded-md absolute top-[170px] z-50"
          >
            Back
          </button>
          <p className="mx-auto w-full text-center mt-40 font-bold text-3xl">
            {temp.title}
          </p>
          <div className="w-full h-[300px] relative mt-8 overflow-x-scroll">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              className="md:w-[50%] w-[90%] h-[250px] "
              modules={[Pagination]}
              loop={true}
              pagination={{ clickable: true }}
            >
              {temp.inerImages.map((slide) => (
                <SwiperSlide key={slide}>
                  <img
                    src={slide}
                    alt={temp.title}
                    className="object-cover h-[85%] w-full  rounded-lg"
                  />
                </SwiperSlide>
              ))}
              <div className="swiper-pagination "></div>
            </Swiper>
            <div className="   mx-auto rounded-lg flex w-[100%] flex-wrap justify-center align-center px-4 mt-[-20px] ">
              {temp.links.Playstore !== "" && (
                <Link
                  to={temp.links.Playstore}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <BsGooglePlay size={20} />
                </Link>
              )}
              {temp.links.Appstore !== "" && (
                <Link
                  to={temp.links.Appstore}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <FaAppStoreIos size={20} />
                </Link>
              )}
              {temp.links.Instagram !== "" && (
                <Link
                  to={temp.links.Instagram}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <BsInstagram size={20} />
                </Link>
              )}
              {temp.links.Facebook !== "" && (
                <Link
                  to={temp.links.Facebook}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <FaFacebookSquare size={20} />
                </Link>
              )}

              {temp.links.Twitter !== "" && (
                <Link
                  to={temp.links.Twitter}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <BsTwitter size={20} />
                </Link>
              )}

              {temp.links.Linkedin !== "" && (
                <Link
                  to={temp.links.Linkedin}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <BsLinkedin size={20} />
                </Link>
              )}

              {temp.links?.Youtube !== "" && (
                <Link
                  to={temp.links.Youtube}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <BsYoutube size={20} />
                </Link>
              )}
              {temp.links?.Tiktok !== "" && (
                <Link
                  to={temp.links.Tiktok}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <FaTiktok size={20} />
                </Link>
              )}
              {temp.links?.Googlemybusiness !== "" && (
                <Link
                  to={temp.links.Googlemybusiness}
                  className="w-[40px] h-[40px] flex justify-center items-center"
                >
                  <SiGooglemybusiness size={20} />
                </Link>
              )}
            </div>
          </div>
          <div className="w-[70%] mx-auto flex flex-wrap mb-10 ">
            <p className="w-[100%] object-contain whitespace-pre-line">
              {temp.description}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default PorfolioPage;
