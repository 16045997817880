import React, { useState } from "react";
import { Link } from "react-router-dom";
import Main from "../assets/TeamImages/Main.png";

const Team = () => {
  const [indicator, setIndicator] = useState(0);

  return (
    <div className="w-full h-full flex  ">
      <div className="flex h-[90%] w-[45%]  flex-col  justify-center pl-20 py-4 text-white">
        <p
          style={{
            color: "#FFF",
            fontSize: "3.03369rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "102%" /* 3.09431rem */,
            textTransform: "uppercase",
            marginBottom: "2.5rem",
          }}
        >
          Meet The ARKies
        </p>
        At The ARK, we're far from your typical team. Our dedicated ARKies, each
        boasting a unique blend of expertise, mastermind strategies that not
        only deliver results but also create an unforgettable journey. We're
        always on the lookout for fresh, innovative talent to join our ranks,
        which is why our doors are wide open for those looking to be a part of
        the excitement. Whether you're a prospective client seeking our expert
        services or a skilled individual eager to come on board, you'll quickly
        realize that at The ARK, we're the kind of team that combines hard work
        with a good time, all while extending a warm welcome to new members.
        <Link
          className="w-[30%] rounded-sm bg-[transparent] text-white mt-8 p-2"
          to={"/team"}
        >
          Read More
        </Link>
      </div>
      <div className="flex h-[90%] w-[50%]">
        <img
          src={Main}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
};

export default Team;
