import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useRef } from "react";
import ArrowNav from "../assets/ArrowNav2.png";

import lottie from "lottie-web";
import mobileAnimationData from "../assets/animations/MobileDevelopment.json";
import webDevelopmentData from "../assets/animations/WebDev.json";
import softwareDevelopmentData from "../assets/animations/SoftwareDev.json";
import artificialIntelligenceData from "../assets/animations/ArtificialInteligence.json";
import blockchainData from "../assets/animations/BlockChain.json";
import SEOData from "../assets/animations/SEO.json";
import digitalMarketingData from "../assets/animations/DigitalMarketing.json";
import videoEditingData from "../assets/animations/VideoEditing.json";
import digitalArtData from "../assets/animations/DigitalArt.json";
import graphicDesignData from "../assets/animations/GraphicDesign.json";
import animationData from "../assets/animations/Animation.json";
import soundData from "../assets/animations/Sound.json";
import videographyData from "../assets/animations/Videography.json";
import crmData from "../assets/animations/CRM.json";
import filmProductionData from "../assets/animations/FilmProduction.json";
import creativeWritingData from "../assets/animations/CreativeWriting.json";
import hrData from "../assets/animations/HR.json";
import brandingData from "../assets/animations/Branding.json";
import bussinessDevelopmentData from "../assets/animations/BussinessDevelopment.json";
import CompanyManegmentData from "../assets/animations/CompanyManegment.json";
import projectManegmentData from "../assets/animations/ProjectManegment.json";
import translationData from "../assets/animations/Translation.json";
import recruitmentData from "../assets/animations/Recruitment.json";
import teamBuildingData from "../assets/animations/TeamBuilding.json";
import communityData from "../assets/animations/Community.json";
import eventsData from "../assets/animations/Events.json";
import telesalesData from "../assets/animations/Telesales.json";
import customerRelationsData from "../assets/animations/CustomerRelation.json";
import stratigicMarkitingData from "../assets/animations/StratigicMarkrting.json";
import socialMediaManegmentData from "../assets/animations/SocialMediaManegment.json";
import stratigicBussinessDevelopmentData from "../assets/animations/StratigicBussinessDevelopment.json";
import financialManegmentData from "../assets/animations/financialManegment.json";
import accountingData from "../assets/animations/Accounting.json";
import ERPData from "../assets/animations/ERP.json";
import LeadManegmentData from "../assets/animations/LeadManegment.json";
import StartupIncubationData from "../assets/animations/StartupIncubation.json";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { db } from "../firebase";

const ServicesPage = () => {
  let { data } = useParams();

  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState({});
  const mobileContainerRef = useRef(null);
  const webContainerRef = useRef(null);
  const softwareContainerRef = useRef(null);
  const artificialContainerRef = useRef(null);
  const blockchainContainerRef = useRef(null);
  const SEOContainerRef = useRef(null);
  const digitalMarketingContainerRef = useRef(null);
  const videoEditingContainerRef = useRef(null);
  const digitalArtContainerRef = useRef(null);
  const graphicDesignContainerRef = useRef(null);
  const animationContainerRef = useRef(null);
  const soundContainerRef = useRef(null);
  const videographyContainerRef = useRef(null);
  const crmContainerRef = useRef(null);
  const filmProductionContainerRef = useRef(null);
  const creativeWritingContainerRef = useRef(null);
  const hrContainerRef = useRef(null);
  const brandingContainerRef = useRef(null);
  const businessDevelopmentContainerRef = useRef(null);
  const companyManegmentContainerRef = useRef(null);
  const projectManegmentContainerRef = useRef(null);
  const translationContainerRef = useRef(null);
  const recruitmentContainerRef = useRef(null);
  const teamBuildingContainerRef = useRef(null);
  const communityContainerRef = useRef(null);
  const eventsContainerRef = useRef(null);
  const salesContainerRef = useRef(null);
  const customerRelationsContainerRef = useRef(null);
  const stratigicMarkitingContainerRef = useRef(null);
  const socialMediaManegmentContainerRef = useRef(null);
  const straticigBussinessDevelopmentContainerRef = useRef(null);
  const financialManegmentContainerRef = useRef(null);
  const accountingContainerRef = useRef(null);
  const ERPContainerRef = useRef(null);
  const leadManegmentContainerRef = useRef(null);
  const startupIncubationContainerRef = useRef(null);

  const popUpContainerRef = useRef(null);

  const Marketing = [
    {
      title: "Digital Marketing",
      description: "Lorem ipsum dolor sit amet consectetur ",
      reference: digitalMarketingContainerRef,
      animationData: digitalMarketingData,
      longDescription: `Elevate your online presence with our comprehensive digital marketing solutions. From strategic planning to campaign execution, we drive engagement and growth, delivering measurable results that make your brand shine. Ready to take your digital journey to the next level? Schedule a call now.`,
    },
    {
      title: "Search Engine Optimization",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: SEOContainerRef,
      animationData: SEOData,
      longDescription: `Boost your online visibility and rankings with our proven SEO strategies. We ensure that your website reaches its full potential, attracting organic traffic and converting visitors into loyal customers. Start your journey to the top of search results – click to book a call now.      `,
    },
    {
      title: "Community Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: communityContainerRef,
      animationData: communityData,
      longDescription: `Foster meaningful connections with your online community through our expert community management services. We're your partner in engagement and growth, ensuring your brand's voice is heard. Connect with us now to see the difference.`,
    },
    {
      title: "Strategic Marketing",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: stratigicMarkitingContainerRef,
      animationData: stratigicMarkitingData,
      longDescription: `Unlock the potential of your brand with our strategic marketing expertise. We craft data-driven strategies to position your business for success, tailored to your unique goals and audience. Your brand's bright future starts here – book a call with us today.`,
    },
    {
      title: "Social Media Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: socialMediaManegmentContainerRef,
      animationData: socialMediaManegmentData,
      longDescription: `Stand out on social media with our expert management. We curate compelling content, foster engagement, and build your brand's online presence. Ready to make a splash on social media? Reach out and let's talk.      `,
    },
  ];

  const Creative = [
    {
      title: "Animation",
      description: "Lorem ipsum dolor sit amet consectetur ",
      reference: animationContainerRef,
      animationData: animationData,
      longDescription: `Transform your ideas into captivating animations. Our expert animators craft visually stunning 2D & 3D content that tells your story and captivates your audience. Ready to bring your vision to life? Click here to schedule a call.`,
    },
    {
      title: "Graphic Design",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: graphicDesignContainerRef,
      animationData: graphicDesignData,
      longDescription: `Turn your brand's visuals into a work of art. Our skilled graphic designers create stunning, on-brand materials that leave a lasting impression. Make your brand unforgettable – click to book a call today.`,
    },
    {
      title: "Digital Art",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: digitalArtContainerRef,
      animationData: digitalArtData,
      longDescription: `Unleash your creativity with our digital art services. Our artists produce unique and striking visuals that make a statement. Ready to stand out in the digital world? Schedule a call now.`,
    },
    {
      title: "Video Editing",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: videoEditingContainerRef,
      animationData: videoEditingData,
      longDescription: `Craft compelling videos that tell your story. Our video editors transform raw footage into professional, engaging content that resonates with your audience. Ready to take your videos to the next level? Reach out today.`,
    },
    {
      title: "Videography",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: videographyContainerRef,
      animationData: videographyData,
      longDescription: `Capture moments that last a lifetime with our skilled videographers. We bring your vision to life, delivering stunning visuals that tell your story. Ready to make your mark with video? Book a call now.`,
    },
    {
      title: "Film Production",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: filmProductionContainerRef,
      animationData: filmProductionData,
      longDescription: `Transform your ideas into cinematic masterpieces with our film production team. We handle every aspect of production, bringing your vision to life on screen. Ready to create your next big hit? Connect with us today.`,
    },
    {
      title: "Creative Writing",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: creativeWritingContainerRef,
      animationData: creativeWritingData,
      longDescription: `Breathe life into your content with our creative writing services. Our wordsmiths craft compelling narratives that resonate with your audience. Ready to tell your story in style? Click to schedule a call.`,
    },
    {
      title: "Sound Engineering",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: soundContainerRef,
      animationData: soundData,
      longDescription: `Perfect your audio with our sound engineering expertise. We deliver crystal-clear, professional audio for your projects. Ready to make your sound stand out? Book a call now.`,
    },
    {
      title: "Translation",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: translationContainerRef,
      animationData: translationData,
      longDescription: `Break down language barriers and reach a global audience with our translation services. Our expert linguists ensure your message is understood worldwide. Ready to expand your reach? Reach out today.`,
    },
  ];

  const Development = [
    {
      title: "Mobile App Development",
      description: "Lorem ipsum dolor sit amet consectetur ",
      reference: mobileContainerRef,
      animationData: mobileAnimationData,
      longDescription: `Bring your ideas to life with our mobile app development team. We create user-friendly, cutting-edge apps that set your brand apart. Ready to transform your vision into a mobile reality? Connect with us today.`,
    },
    {
      title: "Web Development",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: webContainerRef,
      animationData: webDevelopmentData,
      longDescription: `Craft stunning websites that captivate your audience. Our web development experts design and build sites that drive results. Ready to make a lasting impression online? Schedule a call now.`,
    },
    {
      title: "Software Development",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: softwareContainerRef,
      animationData: softwareDevelopmentData,
      longDescription: `Empower your business with our tailored software solutions. We create powerful, efficient software that streamlines operations and drives growth. Ready to innovate and stay ahead? Click to book a call today.`,
    },
    {
      title: "Artificial Intelligence",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: artificialContainerRef,
      animationData: artificialIntelligenceData,
      longDescription: `Harness the power of AI for your business. Our AI experts develop cutting-edge solutions that optimize processes and unlock new possibilities. Ready to explore the future? Reach out now.`,
    },
    {
      title: "Blockchain Technology",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: blockchainContainerRef,
      animationData: blockchainData,
      longDescription: `Join the blockchain revolution with our expert guidance. We provide end-to-end blockchain solutions that revolutionize your business. Ready to embark on your blockchain journey? Connect with us today.`,
    },
  ];

  const Business = [
    {
      title: "Human Resource Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: hrContainerRef,
      animationData: hrData,
      longDescription: `Optimize your workforce with our human resource management expertise. We handle all HR aspects, ensuring your team is motivated and thriving. Ready to transform your workplace? Schedule a call now.`,
    },
    {
      title: "Branding",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: brandingContainerRef,
      animationData: brandingData,
      longDescription: `Define and strengthen your brand identity with our expert branding services. We craft compelling brand stories that leave a lasting impression. Ready to create a brand that resonates? Reach out and let's talk.`,
    },
    {
      title: "Company Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: companyManegmentContainerRef,
      animationData: CompanyManegmentData,
      longDescription: `Streamline your operations and boost efficiency with our company management services. We take care of the details, so you can focus on growth. Ready to lead your business to success? Connect with us today.`,
    },

    {
      title: "Project Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: projectManegmentContainerRef,
      animationData: projectManegmentData,
      longDescription: `Ensure your projects run smoothly from start to finish with our expert project management services. We're your partners in successful project delivery. Ready to achieve project excellence? Schedule a call today.`,
    },

    {
      title: "Recruitment",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: recruitmentContainerRef,
      animationData: recruitmentData,
      longDescription: `Find the perfect talent for your team with our recruitment services. We connect you with top talent that aligns with your vision and goals. Ready to build a dream team? Click here to book a call.`,
    },
    {
      title: "Strategic Business Development",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: straticigBussinessDevelopmentContainerRef,
      animationData: stratigicBussinessDevelopmentData,
      longDescription: `Unlock growth opportunities with our strategic business development experts. We create dynamic plans that drive success. Ready to take your business to the next level? Book a call now.      `,
    },
    {
      title: "Financial Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: financialManegmentContainerRef,
      animationData: financialManegmentData,
      longDescription: `Take control of your finances with our financial management experts. We maximize your resources and set your business on the path to prosperity. Ready to secure your financial future? Click here to schedule a call.      `,
    },
    {
      title: "Accounting",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: accountingContainerRef,
      animationData: accountingData,
      longDescription: `Ensure accuracy and transparency in your financial operations with our accounting services. We handle the numbers, so you can focus on what you do best. Ready to have peace of mind about your finances? Reach out and let's talk.      `,
    },
    {
      title: "Enterprise Resource Planning",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: ERPContainerRef,
      animationData: ERPData,
      longDescription: `Optimize your resources and operations with our enterprise resource planning solutions. We deliver comprehensive strategies for success. Ready to drive efficiency and growth? Connect with us today.      `,
    },
  ];

  const Sales = [
    {
      title: "Telesales Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: salesContainerRef,
      animationData: telesalesData,
      longDescription: `Optimize your tele-sales efforts with our expert management. We increase your sales conversion rates and grow your business. Ready to boost your sales? Schedule a call now.`,
    },
    {
      title: "Customer Relations Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: customerRelationsContainerRef,
      animationData: customerRelationsData,
      longDescription: `Build lasting customer relationships with our CRM solutions. We help you connect, engage, and retain your valued clients. Ready to strengthen your customer bonds? Click to book a call today.`,
    },
    {
      title: "Lead Management",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: leadManegmentContainerRef,
      animationData: LeadManegmentData,
      longDescription: `Maximize your leads with our lead management expertise. We streamline the process, ensuring no opportunity goes to waste. Ready to take control of your leads? Reach out now. `,
    },
  ];

  const Specialized = [
    {
      title: "Event Coordination",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: eventsContainerRef,
      animationData: eventsData,
      longDescription: `Create memorable events with our expert coordination. We handle every detail, making your events a resounding success. Ready to plan your next event? Schedule a call today.`,
    },
    {
      title: "Team Building Events",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: teamBuildingContainerRef,
      animationData: teamBuildingData,
      longDescription: `Strengthen your team with our team-building events. We create engaging, transformative experiences that enhance teamwork and morale. Ready to boost team cohesion? Connect with us today.`,
    },
    {
      title: "Startup Incubation",
      description: "Lorem ipsum dolor sit amet cnsectetur",
      reference: startupIncubationContainerRef,
      animationData: StartupIncubationData,
      longDescription: `Nurture your startup with our incubation support. We provide guidance and resources to accelerate your growth. Ready to turn your startup into a success story? Book a call now.`,
    },
  ];

  const tempData =
    data === "Marketing"
      ? Marketing
      : data === "Creative"
      ? Creative
      : data === "Development"
      ? Development
      : data === "Business"
      ? Business
      : data === "Sales"
      ? Sales
      : Specialized;

  React.useEffect(() => {
    let anim,
      anim2,
      anim3,
      anim4,
      anim5,
      anim6,
      anim7,
      anim8,
      anim9,
      anim10,
      anim11,
      anim12,
      anim13,
      anim14,
      anim15,
      anim16,
      anim17,
      anim18,
      anim19,
      anim20,
      anim21,
      anim22,
      anim23,
      anim24,
      anim25,
      anim26,
      anim27,
      anim28,
      anim29,
      anim30,
      anim31,
      anim32,
      anim33,
      anim34,
      anim35,
      anim36;
    if (data === "Development") {
      anim = lottie.loadAnimation({
        container: mobileContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: mobileAnimationData, // The JSON animation data
      });

      anim2 = lottie.loadAnimation({
        container: webContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: webDevelopmentData, // The JSON animation data
      });

      anim3 = lottie.loadAnimation({
        container: softwareContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: softwareDevelopmentData, // The JSON animation data
      });

      anim4 = lottie.loadAnimation({
        container: artificialContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: artificialIntelligenceData, // The JSON animation data
      });

      anim5 = lottie.loadAnimation({
        container: blockchainContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: blockchainData, // The JSON animation data
      });
    } else if (data === "Marketing") {
      anim7 = lottie.loadAnimation({
        container: digitalMarketingContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: digitalMarketingData, // The JSON animation data
      });
      anim6 = lottie.loadAnimation({
        container: SEOContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: SEOData, // The JSON animation data
      });
      anim25 = lottie.loadAnimation({
        container: communityContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: communityData, // The JSON animation data
      });

      anim29 = lottie.loadAnimation({
        container: stratigicMarkitingContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: stratigicMarkitingData, // The JSON animation data
      });

      anim30 = lottie.loadAnimation({
        container: socialMediaManegmentContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: socialMediaManegmentData, // The JSON animation data
      });
    } else if (data === "Creative") {
      anim11 = lottie.loadAnimation({
        container: animationContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: animationData, // The JSON animation data
      });
      anim10 = lottie.loadAnimation({
        container: graphicDesignContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: graphicDesignData, // The JSON animation data
      });
      anim9 = lottie.loadAnimation({
        container: digitalArtContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: digitalArtData, // The JSON animation data
      });
      anim8 = lottie.loadAnimation({
        container: videoEditingContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: videoEditingData, // The JSON animation data
      });
      anim13 = lottie.loadAnimation({
        container: videographyContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: videographyData, // The JSON animation data
      });
      anim15 = lottie.loadAnimation({
        container: filmProductionContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: filmProductionData, // The JSON animation data
      });
      anim16 = lottie.loadAnimation({
        container: creativeWritingContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: creativeWritingData, // The JSON animation data
      });
      anim22 = lottie.loadAnimation({
        container: translationContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: translationData, // The JSON animation data
      });
      anim14 = lottie.loadAnimation({
        container: soundContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: soundData, // The JSON animation data
      });
    } else if (data === "Business") {
      anim17 = lottie.loadAnimation({
        container: hrContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: hrData, // The JSON animation data
      });

      anim18 = lottie.loadAnimation({
        container: brandingContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: brandingData, // The JSON animation data
      });

      // anim19 = lottie.loadAnimation({
      //   container: businessDevelopmentContainerRef.current,
      //   renderer: "svg", // You can choose 'canvas' or 'html' as well
      //   loop: true,
      //   autoplay: true,
      //   animationData: bussinessDevelopmentData, // The JSON animation data
      // });

      anim20 = lottie.loadAnimation({
        container: companyManegmentContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: CompanyManegmentData, // The JSON animation data
      });

      anim21 = lottie.loadAnimation({
        container: projectManegmentContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: projectManegmentData, // The JSON animation data
      });

      anim23 = lottie.loadAnimation({
        container: recruitmentContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: recruitmentData, // The JSON animation data
      });

      anim31 = lottie.loadAnimation({
        container: straticigBussinessDevelopmentContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: stratigicBussinessDevelopmentData, // The JSON animation data
      });

      anim32 = lottie.loadAnimation({
        container: financialManegmentContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: financialManegmentData, // The JSON animation data
      });

      anim33 = lottie.loadAnimation({
        container: accountingContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: accountingData, // The JSON animation data
      });

      anim34 = lottie.loadAnimation({
        container: ERPContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: ERPData, // The JSON animation data
      });
    } else if (data === "Sales") {
      anim27 = lottie.loadAnimation({
        container: salesContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: telesalesData, // The JSON animation data
      });

      anim28 = lottie.loadAnimation({
        container: customerRelationsContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: customerRelationsData, // The JSON animation data
      });

      anim35 = lottie.loadAnimation({
        container: leadManegmentContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: LeadManegmentData, // The JSON animation data
      });
    } else if (data === "Specialized") {
      anim24 = lottie.loadAnimation({
        container: teamBuildingContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: teamBuildingData, // The JSON animation data
      });

      anim26 = lottie.loadAnimation({
        container: eventsContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: eventsData, // The JSON animation data
      });

      anim36 = lottie.loadAnimation({
        container: startupIncubationContainerRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: true,
        autoplay: true,
        animationData: StartupIncubationData, // The JSON animation data
      });
    }
    return () => {
      if (anim) anim.destroy();
      if (anim2) anim2.destroy();
      if (anim3) anim3.destroy();
      if (anim4) anim4.destroy();
      if (anim5) anim5.destroy();
      if (anim6) anim6.destroy();
      if (anim7) anim7.destroy();
      if (anim8) anim8.destroy();
      if (anim9) anim9.destroy();
      if (anim10) anim10.destroy();
      if (anim11) anim11.destroy();
      if (anim12) anim12.destroy();
      if (anim13) anim13.destroy();
      if (anim14) anim14.destroy();
      if (anim15) anim15.destroy();
      if (anim16) anim16.destroy();
      if (anim17) anim17.destroy();
      if (anim18) anim18.destroy();
      if (anim19) anim19.destroy();
      if (anim20) anim20.destroy();
      if (anim21) anim21.destroy();
      if (anim22) anim22.destroy();
      if (anim23) anim23.destroy();
      if (anim24) anim24.destroy();
      if (anim25) anim25.destroy();
      if (anim26) anim26.destroy();
      if (anim27) anim27.destroy();
      if (anim28) anim28.destroy();
      if (anim29) anim29.destroy();
      if (anim30) anim30.destroy();
      if (anim31) anim31.destroy();
      if (anim32) anim32.destroy();
      if (anim33) anim33.destroy();
      if (anim34) anim34.destroy();
      if (anim35) anim35.destroy();
      if (anim36) anim36.destroy();
    };
  }, [data]);

  const [tempanimation, setTempAnimation] = useState(null);

  const loadAnimation = (animationData, container) => {
    if (tempanimation) tempanimation.destroy();
    let anim = lottie.loadAnimation({
      container: container,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: true,
      autoplay: true,
      animationData: animationData, // The JSON animation data
    });
    setTempAnimation(anim);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selecting, setSelecting] = useState(false);
  const [fillingData, setFillingData] = useState(false);

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");

  const messageToSned = `Name: ${first} ${last} %0aNumber: ${number} %0aEmail: ${email}%0aService: ${selected.title} %0aDate: ${selectedDate} %0aMessage: ${message}`;
  const phoneNumber = "96170318605";

  const handleDateChange = (date) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    const tempDate = new Intl.DateTimeFormat("en-US", options).format(date);
    setSelectedDate(tempDate);
  };

  const handleSubmit = () => {
    if (
      first === "" ||
      last === "" ||
      number === "" ||
      email === "" ||
      message === "" ||
      selectedDate === ""
    ) {
      alert("Please fill all the fields. Thank you.");
      return;
    } else {
      db.collection("Appointments")
        .add({
          firstName: first,
          lastName: last,
          email: email,
          phoneNumber: number,
          message: message,
          date: selectedDate,
          service: selected.title,
        })
        .then(() => {
          window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageToSned}`;

          alert("Message has been submitted successfully. Thank you.");
          setEmail("");
          setFirst("");
          setLast("");
          setNumber("");
          setMessage("");
        });
    }
  };

  const [temp, settemp] = useState(false);

  return (
    <div>
      <Navbar inHome={false} setNavOpened={settemp} />
      <div className="flex flex-wrap  md:px-20 px-4 mt-32">
        {tempData.map((item, index) => (
          <div
            key={index}
            className="md:w-1/4 w-[100%] md:h-[200px] h-[200px] p-2 flex items-center justify-center flex-col md:hover:scale-125 transition-all duration-1000 ease-in-out md:mt-0 mt-4"
            onClick={() => {
              setActive(true);
              setSelected(item);
              loadAnimation(item.animationData, popUpContainerRef.current);
            }}
          >
            <div
              className="w-[50%] md:h-[50%] h-[80%]  flex items-center justify-center"
              ref={item.reference}
            ></div>
            <p className="md:font-bold md:text-lg text-xl  text-center">
              {item.title}
            </p>
          </div>
        ))}
      </div>
      <div
        className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50  transition-all duration-1000 ease-in-out  ${
          active ? "block backdrop-blur-md" : "hidden"
        }`}
      >
        <div className="sm:w-[80%] sm:h-[80%] w-[100%] h-[100%] bg-[#00000072] flex flex-col md:flex-row rounded-md relative text-white ">
          <div
            className="absolute right-4 top-4 w-[40px] h-[20px] items-center justify-center flex cursor-pointer p-4 bg-[transparent] px-6 py-4 rounded-sm text-[#e0e0e0] z-50"
            onClick={() => {
              setActive(false);
              tempanimation.destroy();
              setSelecting(false);
              setFillingData(false);
            }}
          >
            X
          </div>
          <div className="flex flex-col  w-[100%] h-[50%]  md:w-1/3 md:h-[100%] items-center justify-center p-4">
            <div
              className="flex w-[70%] md:w-[100%] items-center justify-center"
              ref={popUpContainerRef}
            ></div>
            <p className="flex h-[30%] text-lg md:font-bold mt-4">
              {selected.title}
            </p>
          </div>
          <div className="flex flex-col w-[100%] h-[50%] overflow-y-auto md:w-2/3 md:h-[100%]  justify-center p-8 items-center">
            {selecting && !fillingData && (
              <>
                <Calendar
                  className="rounded-lg text-black"
                  minDate={new Date()}
                  tileDisabled={({ date }) =>
                    date.getDay("Sunday") === 0 || date.getDay("Saturday") === 6
                  }
                  value={selectedDate}
                  onChange={handleDateChange}
                />
                <button
                  onClick={() => {
                    setFillingData(true);
                  }}
                  className="mt-8  px-6 py-2 rounded-sm text-[#e0e0e0]"
                >
                  Next
                </button>
              </>
            )}
            {!selecting && !fillingData && (
              <>
                <p className="text-white text-center">
                  {selected.longDescription}
                </p>
                <button
                  onClick={() => setSelecting(true)}
                  className="mt-8  px-6 py-2 rounded-sm text-[#e0e0e0]"
                >
                  Book an Appoitment
                </button>
              </>
            )}

            {fillingData && (
              <div className="flex flex-1 flex-col  w-[100%] h-[100%] justify-center">
                <div className="flex flex-col mt-12 md:mt-4">
                  <input
                    className="rounded-sm bg-transparent border-b-2 border-black placeholder-white"
                    value={first}
                    onChange={(e) => setFirst(e.target.value)}
                    placeholder="First Name"
                  />
                </div>

                <div className="flex flex-col mt-4">
                  <input
                    className="rounded-sm bg-transparent border-b-2 border-black placeholder-white"
                    value={last}
                    onChange={(e) => setLast(e.target.value)}
                    placeholder="Last Name"
                  />

                  <div className="flex flex-col mt-4">
                    <input
                      className="rounded-sm bg-transparent border-b-2 border-black placeholder-white"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />

                    <div className="flex flex-col mt-4">
                      <input
                        className="rounded-sm bg-transparent border-b-2 border-black placeholder-white"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        placeholder="Phone Number"
                      />

                      <div className="flex flex-col mt-4">
                        <textarea
                          className="rounded-sm  h-auto bg-transparent border-b-2 border-black border-x-0 border-t-0 placeholder-white p-0"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Message"
                        />
                      </div>
                      <div className="flex flex-row w-full justify-evenly mb-4 md:mb-0">
                        <button
                          className="mt-8  px-6 py-2 rounded-sm text-[#e0e0e0]"
                          onClick={() => {
                            setSelecting(false);
                            setFillingData(false);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className="mt-8  px-6 py-2 rounded-sm text-[#e0e0e0]"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
