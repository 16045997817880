import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [indicator, setIndicator] = useState(false);
  useEffect(() => {
    //make time out for 800ms
    setTimeout(() => {
      setIndicator(true);
    }, 10);
  }, []);
  return (
    <div
      className={`w-full h-full flex  pt-8 justify-evenly  transition-all duration-100 text-white ${
        indicator ? "text-md" : "text-[1px]"
      }`}
    >
      <div className="h-full w-[16%] flex flex-col  mx-2 ">
        <p className="font-bold mb-2 ">Technical Development & Innovation</p>
        <Link
          to={`/portfolio/Mobile App Development`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Mobile App Development
        </Link>
        <Link
          to={`/portfolio/Web Development`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Web Development
        </Link>
        <Link
          to={`/portfolio/Software Development`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Software Development
        </Link>

        <Link
          to={`/portfolio/Blockchain Technology`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Blockchain Technology
        </Link>
        <Link
          to={`/portfolio/Game Development`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Game Development
        </Link>
      </div>

      <div className="bg-[#ffffff22] w-[0.5px] h-[75%]"></div>

      <div className="h-full w-[16%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Business Development & Management</p>

        <Link
          to={`/portfolio/Recruitment`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Recruitment
        </Link>
        <Link
          to={`/portfolio/Branding`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Branding
        </Link>
        <Link
          to={`/portfolio/Company Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Company Management
        </Link>
        <Link
          to={`/portfolio/Strategic Business Development`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Strategic Business Development
        </Link>

        <Link
          to={`/portfolio/Enterprise Resource Planning`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Enterprise Resource Planning
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[0.5px] h-[75%]"></div>

      <div className="h-full w-[16%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Sales & Customer Relations</p>
        <Link
          to={`/portfolio/Lead Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Lead Management
        </Link>{" "}
        <Link
          to={`/portfolio/Customer Relationship Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          CRM
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[0.5px] h-[75%]"></div>

      <div className="h-full w-[15%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Digital Presence & Marketing</p>
        <Link
          to={`/portfolio/Digital Marketing`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Digital Marketing
        </Link>
        <Link
          to={`/portfolio/Strategic Marketing`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Strategic Marketing
        </Link>
        <Link
          to={`/portfolio/SEO`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Search Engine Optimization
        </Link>
        <Link
          to={`/portfolio/Community Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Community Management
        </Link>
        <Link
          to={`/portfolio/Social Media Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Social Media Management
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[1px] h-[75%]"></div>

      <div className="h-full w-[15%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Creative Content & Production</p>
        <Link
          to={`/portfolio/Animation`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Animation
        </Link>
        <Link
          to={`/portfolio/Graphic Design`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Graphic Design
        </Link>
        <Link
          to={`/portfolio/Digital Art`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Digital Art
        </Link>
        <Link
          to={`/portfolio/Video Editing`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Video Editing
        </Link>
        <Link
          to={`/portfolio/Videography`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Videography
        </Link>
        <Link
          to={`/portfolio/Film Production`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Film Production
        </Link>
        <Link
          to={`/portfolio/Creative Writing`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Creative Writing
        </Link>
        <Link
          to={`/portfolio/Translation`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Translation
        </Link>
        <Link
          to={`/portfolio/Package Design`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Package Design
        </Link>
        <Link
          to={`/portfolio/Merchandise`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Merchandise
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[1px] h-[75%]"></div>
      <div className="h-full w-[16%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Specialized Services</p>
        <Link
          to={`/portfolio/Event Coordination`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Event Coordination
        </Link>
        <Link
          to={`/portfolio/Team Building Events`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          The ARK Events
        </Link>
        <Link
          to={`/portfolio/Startup Incubation`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Startup Incubation
        </Link>
      </div>
    </div>
  );
};

export default Portfolio;
