import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { db } from "../firebase";

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  let { document } = useParams();
  let { collection } = useParams();
  const [temp2, settemp2] = useState(false);

  const [temp, setTemp] = useState([]);

  useEffect(() => {
    db.collection("Blogs")
      .doc(document)
      .collection(collection)
      .get()
      .then((snapshot) => {
        setBlogs(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            description: doc.data().description,
            image: doc.data().image,
            innerImage: doc.data().innerImage,
          }))
        );
      });
  }, [document, collection]);

  console.log(blogs);

  const [show, setShow] = useState(false);
  return (
    <>
      {!show && (
        <div>
          <Navbar inHome={false} setNavOpened={settemp2} />
          <div className="flex flex-wrap md:px-36 px-4 mt-40">
            {blogs.map((blog, index) => (
              <div
                key={index}
                className="w-full md:w-1/4 p-4 cursor-pointer"
                onClick={() => {
                  setShow(true);
                  setTemp(blog);
                  console.log(blog);
                }}
              >
                <div className="bg-white rounded-lg shadow-lg">
                  <img
                    src={blog.image}
                    alt=""
                    className="w-full h-52 object-cover rounded-t-lg"
                  />
                  <div className=" bottom-[-100%] h-8  w-full z-10 flex items-center justify-center px-4 font-bold text-center  mx-auto">
                    {blog.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {show && (
        <div className=" pt-36">
          <Navbar inHome={false} setNavOpened={settemp2} />
          <button
            onClick={() => setShow(false)}
            className="ml-4 mt-2 cursor-pointer bg-[transparent] text-black border-[2px] border-black text-center py-2 px-4 rounded-md absolute"
          >
            Back
          </button>
          <p className="mx-auto w-full text-center mt-2 font-bold text-3xl">
            {temp.title}
          </p>
          <div className="w-full h-[250px] relative mt-8 overflow-x-scroll flex items-center justify-center rounded-md ">
            {/* <Swiper
              spaceBetween={50}
              slidesPerView={1}
              className="w-[50%] h-[100%] pb-10"
              modules={[Pagination]}
              loop={true}
              pagination={{ clickable: true }}
            >
              {temp.inerImages.map((slide) => (
                <SwiperSlide key={slide}>
                  <img
                    src={slide}
                    alt={temp.title}
                    className="object-cover h-[85%] w-full  rounded-lg"
                  />
                </SwiperSlide>
              ))}
              <div className="swiper-pagination "></div>
            </Swiper> */}

            <img
              src={temp?.innerImage}
              alt=""
              className="rounded-md flex  md:w-[50%] w-[90%]   h-[100%]  object-cover "
            />
          </div>
          <div className="w-[70%] mx-auto flex flex-wrap mb-10 ">
            <p className="w-[100%] object-contain whitespace-pre-line">
              {temp.description}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogsPage;
