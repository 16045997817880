import React, { useEffect, useState } from "react";
import LogoBig from "../assets/BigLogo.png";
import { Link } from "react-router-dom";

const Blogs = () => {
  const [indicator, setIndicator] = useState(false);
  useEffect(() => {
    //make time out for 800ms
    setTimeout(() => {
      setIndicator(true);
    }, 10);
  }, []);
  return (
    <div
      className={`w-full h-full flex  pt-8 justify-evenly  transition-all duration-75 text-white ${
        indicator ? "text-md" : "text-[1px]"
      }`}
    >
      <div className="h-full w-[15%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Digital Presence & Marketing</p>
        <Link
          to={`/blogs/DigitalPresence/Digital Marketing`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Digital Marketing
        </Link>
        <Link
          to={`/blogs/DigitalPresence/Strategic Marketing%20`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Strategic Marketing
        </Link>
        <Link
          to={`/blogs/DigitalPresence/SEO`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Search Engine Optimization
        </Link>
        <Link
          to={`/blogs/DigitalPresence/Community Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Community Management
        </Link>
        <Link
          to={`/blogs/DigitalPresence/Social Media Management%20`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Social Media Management
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[0.5px] h-[75%]"></div>
      <div className="h-full w-[15%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Creative Content & Production</p>
        <Link
          to={`/blogs/CreativeContent/Animation`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Animation
        </Link>
        <Link
          to={`/blogs/CreativeContent/Graphic Design`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Graphic Design
        </Link>
        <Link
          to={`/blogs/CreativeContent/Digital Art`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Digital Art
        </Link>
        <Link
          to={`/blogs/CreativeContent/Video Editing`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Video Editing
        </Link>
        <Link
          to={`/blogs/CreativeContent/Videography`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Videography
        </Link>
        <Link
          to={`/blogs/CreativeContent/Film Production`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Film Production
        </Link>
        <Link
          to={`/blogs/CreativeContent/Creative Writing`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Creative Writing
        </Link>
        <Link
          to={`/blogs/CreativeContent/Translation`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Translation
        </Link>
        <Link
          to={`/blogs/CreativeContent/Sound Engineering `}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Sound Engineering
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[1px] h-[75%]"></div>

      <div className="h-full w-[16%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Sales & Customer Relations</p>
        <Link
          to={`/blogs/SalesandCustomerRelations/Telesales Management`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Telesales Management
        </Link>
        <Link
          to={`/blogs/SalesandCustomerRelations/Lead Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Lead Management
        </Link>{" "}
        <Link
          to={`/blogs/SalesandCustomerRelations/Customer Relationship Management`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          CRM
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[1px] h-[75%]"></div>

      <div className="h-full w-[16%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Technical Development & Innovation</p>
        <Link
          to={`/blogs/TechnicalDevelopment/Mobile App Development`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Mobile App Development
        </Link>
        <Link
          to={`/blogs/TechnicalDevelopment/Web Development`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Web Development
        </Link>
        <Link
          to={`/blogs/TechnicalDevelopment/Software Development`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Software Development
        </Link>
        <Link
          to={`/blogs/TechnicalDevelopment/Artificial Intelligence`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Artificial Intelligence
        </Link>
        <Link
          to={`/blogs/TechnicalDevelopment/Blockchain Technology`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Blockchain Technology
        </Link>
        <Link
          to={`/blogs/TechnicalDevelopment/Game Development`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Game Development
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[1px] h-[75%]"></div>

      <div className="h-full w-[16%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Business Development & Management</p>
        <Link
          to={`/blogs/BusinessDevelopment/HRManagement`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Human Resource Management
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/Recruitment`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Recruitment
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/Branding`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Branding
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/CompanyManagement`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Company Management
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/StrategicBusinessDevelopment`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Strategic Business Development
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/ProjectManagement`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Project Management
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/FinancialManagement`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Financial Management
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/Accounting`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Accounting
        </Link>
        <Link
          to={`/blogs/BusinessDevelopment/EnterpriseResourcePlanning`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Enterprise Resource Planning
        </Link>
      </div>
      <div className="bg-[#ffffff22] w-[1px] h-[75%]"></div>

      <div className="h-full w-[16%] flex flex-col  mx-2">
        <p className="font-bold mb-2 ">Specialized Services</p>
        <Link
          to={`/blogs/SpecializedServices/Event Coordination`}
          className="mt-2  border-b-2 text-[#a1a1a1] border-[transparent] hover:border-[#ffffff] hover:text-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Event Coordination
        </Link>
        <Link
          to={`/blogs/SpecializedServices/Team Building Events`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          The ARK Events
        </Link>
        <Link
          to={`/blogs/SpecializedServices/Startup Incubation`}
          className="mt-2  border-b-2 border-[transparent] text-[#a1a1a1] hover:text-[#ffffff] hover:border-[#ffffff] transition-all duration-1000 ease-in-out w-max"
        >
          Startup Incubation
        </Link>
      </div>
    </div>
  );
};

export default Blogs;
