import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Welcome from "./Welcome";
import Home from "./Home";
import Footer from "../components/Footer1";

const Main = () => {
  const [navOpened, setNavOpened] = useState(false);
  return (
    <>
      <div
        id="Main"
        className="overscroll-none overscroll-y-none overflow-hidden "
      >
        <Navbar inHome={true} setNavOpened={setNavOpened} />
        <Welcome navOpened={navOpened} />
      </div>
    </>
  );
};

export default Main;
