import React, { useEffect, useState } from "react";
import LogoBlack from "../assets/LogoBlack.png";
import LogoWhite from "../assets/LogoWhite.png";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { PiInstagramLogoLight } from "react-icons/pi";
import { FaFacebookSquare } from "react-icons/fa";
import { BsLinkedin, BsPhoneVibrateFill } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import { PiPhoneDisconnectFill } from "react-icons/pi";
import { MdEmail } from "react-icons/md";
import Aboutus from "./Aboutus";
import Services from "./Services";
import Blogs from "./Blogs";
import Team from "./Team";
import ContactUs from "./ContactUs";
import { Link } from "react-router-dom";
import Portfolio from "./Portfolio";
import lottie from "lottie-web";
import ExitIconData from "../assets/animations/ExitIcon.json";
import { useRef } from "react";
import ExitIcon from "../assets/x.png";
import { db } from "../firebase";

const Navbar = ({ inHome, setNavOpened }) => {
  const [hover, setHover] = useState("");
  const [hoverMobile, setHoverMobile] = useState("");

  const [tempLinks, setTempLinks] = useState([]);

  const [open, setOpen] = useState(false);
  const [navMobile, setNavMobile] = useState(false);

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (
      first === "" ||
      last === "" ||
      email === "" ||
      number === "" ||
      message === ""
    ) {
      alert("Please fill all the fields");
      return;
    } else {
      db.collection("ContactUs")
        .add({
          first: first,
          last: last,
          email: email,
          number: number,
          message: message,
        })
        .then(() => {
          alert("Thank you for contacting us, we will get back to you soon");
          setFirst("");
          setLast("");
          setEmail("");
          setNumber("");
          setMessage("");
        });
    }
  };

  const services = [
    {
      title: "Digital Presence & Marketing",
      indicator: "Marketing",
    },
    {
      title: "Creative Content & Production",
      indicator: "Creative",
    },
    {
      title: "Technical Development & Innovation",
      indicator: "Development",
    },
    {
      title: "Business Development & Management",
      indicator: "Business",
    },
    {
      title: "Sales and Customer Relations",
      indicator: "Sales",
    },
    {
      title: "Specialized Services",
      indicator: "Specialized",
    },
  ];

  const portfolio = [
    {
      title: "Technical Development & Innovation",
      links: [
        {
          title: "Mobile App Development",
          link: "/portfolio/Mobile App Development",
        },
        {
          title: "Web Development",
          link: "/portfolio/Web Development",
        },
        {
          title: "Software Development",
          link: "/portfolio/Software Development",
        },
        {
          title: "Blockchain Technology",
          link: "/portfolio/Blockchain Technology",
        },
        {
          title: "Game Development",
          link: "/portfolio/Game Development",
        },
      ],
    },
    {
      title: "Business Development & Management",
      links: [
        {
          title: "Recruitment",
          link: "/portfolio/Recruitment",
        },
        {
          title: "Branding",
          link: "/portfolio/Branding",
        },
        {
          title: "Company Management",
          link: "/portfolio/Company Management",
        },
        {
          title: "Strategic Business Development",
          link: "/portfolio/Strategic Business Development",
        },
        {
          title: "Enterprise Resource Planning",
          link: "/portfolio/Enterprise Resource Planning",
        },
      ],
    },
    {
      title: "Sales & Customer Relations",
      links: [
        {
          title: "Lead Management",
          link: "/portfolio/Lead Management",
        },
        {
          title: "CRM",
          link: "/portfolio/Customer Relationship Management",
        },
      ],
    },
    {
      title: "Digital Presence & Marketing",
      links: [
        {
          title: "Digital Marketing",
          link: "/portfolio/Digital Marketing",
        },
        {
          title: "Strategic Marketing",
          link: "/portfolio/Strategic Marketing",
        },
        {
          title: "Search Engine Optimization",
          link: "/portfolio/SEO",
        },
        {
          title: "Community Management",
          link: "/portfolio/Community Management",
        },
        {
          title: "Social Media Management",
          link: "/portfolio/Social Media Management",
        },
      ],
    },
    {
      title: "Creative Content & Production",
      links: [
        {
          title: "Animation",
          link: "/portfolio/Animation",
        },
        {
          title: "Graphic Design",
          link: "/portfolio/Graphic Design",
        },
        {
          title: "Digital Art",
          link: "/portfolio/Digital Art",
        },
        {
          title: "Video Editing",
          link: "/portfolio/Video Editing",
        },
        {
          title: "Videography",
          link: "/portfolio/Videography",
        },
        {
          title: "Film Production",
          link: "/portfolio/Film Production",
        },
        {
          title: "Creative Writing",
          link: "/portfolio/Creative Writing",
        },
        {
          title: "Translation",
          link: "/portfolio/Translation",
        },
        {
          title: "Package Design",
          link: "/portfolio/Package Design",
        },
        {
          title: "Merchandise",
          link: "/portfolio/Merchandise",
        },
      ],
    },
    {
      title: "Specialized Services",
      links: [
        {
          title: "Event Coordination",
          link: "/portfolio/Event Coordination",
        },
        {
          title: "Team Building Events",
          link: "/portfolio/Team Building Events",
        },
        {
          title: "Startup Incubation",
          link: "/portfolio/Startup Incubation",
        },
      ],
    },
  ];

  const blogs = [
    {
      title: "Digital Presence & Marketing",
      links: [
        {
          title: "Digital Marketing",
          link: "/blogs/DigitalPresence/Digital Marketing",
        },
        {
          title: "Strategic Marketing",
          link: "/blogs/DigitalPresence/Strategic Marketing",
        },
        {
          title: "Search Engine Optimization",
          link: "/blogs/DigitalPresence/SEO",
        },
        {
          title: "Community Management",
          link: "/blogs/DigitalPresence/Community Management",
        },
        {
          title: "Social Media Management",
          link: "/blogs/DigitalPresence/Social Media Management%20",
        },
      ],
    },
    {
      title: "Creative Content & Production",
      links: [
        { title: "Animation", link: "/blogs/CreativeContent/Animation" },
        {
          title: "Graphic Design",
          link: "/blogs/CreativeContent/Graphic Design",
        },
        { title: "Digital Art", link: "/blogs/CreativeContent/Digital Art" },
        {
          title: "Video Editing",
          link: "/blogs/CreativeContent/Video Editing",
        },
        { title: "Videography", link: "/blogs/CreativeContent/Videography" },
        {
          title: "Film Production",
          link: "/blogs/CreativeContent/Film Production",
        },
        {
          title: "Creative Writing",
          link: "/blogs/CreativeContent/Creative Writing",
        },
        { title: "Translation", link: "/blogs/CreativeContent/Translation" },
        {
          title: "Sound Engineering",
          link: "/blogs/CreativeContent/Sound Engineering ",
        },
      ],
    },
    {
      title: "Sales & Customer Relations",
      links: [
        {
          title: "Telesales Management",
          link: "/blogs/SalesandCustomerRelations/Telesales Management",
        },
        {
          title: "Lead Management",
          link: "/blogs/SalesandCustomerRelations/Lead Management",
        },
        {
          title: "CRM",
          link: "/blogs/SalesandCustomerRelations/Customer Relationship Management",
        },
      ],
    },
    {
      title: "Technical Development & Innovation",
      links: [
        {
          title: "Mobile App Development",
          link: "/blogs/TechnicalDevelopment/Mobile App Development",
        },
        {
          title: "Web Development",
          link: "/blogs/TechnicalDevelopment/Web Development",
        },
        {
          title: "Software Development",
          link: "/blogs/TechnicalDevelopment/Software Development",
        },
        {
          title: "Artificial Intelligence",
          link: "/blogs/TechnicalDevelopment/Artificial Intelligence",
        },
        {
          title: "Blockchain Technology",
          link: "/blogs/TechnicalDevelopment/Blockchain Technology",
        },
        {
          title: "Game Development",
          link: "/blogs/TechnicalDevelopment/Game Development",
        },
      ],
    },
    {
      title: "Business Development & Management",
      links: [
        {
          title: "Human Resource Management",
          link: "/blogs/BusinessDevelopment/HRManagement",
        },
        {
          title: "Recruitment",
          link: "/blogs/BusinessDevelopment/Recruitment",
        },
        { title: "Branding", link: "/blogs/BusinessDevelopment/Branding" },
        {
          title: "Company Management",
          link: "/blogs/BusinessDevelopment/CompanyManagement",
        },
        {
          title: "Strategic Business Development",
          link: "/blogs/BusinessDevelopment/StrategicBusinessDevelopment",
        },
        {
          title: "Project Management",
          link: "/blogs/BusinessDevelopment/ProjectManagement",
        },
        {
          title: "Financial Management",
          link: "/blogs/BusinessDevelopment/FinancialManagement",
        },
        { title: "Accounting", link: "/blogs/BusinessDevelopment/Accounting" },
        {
          title: "Enterprise Resource Planning",
          link: "/blogs/BusinessDevelopment/EnterpriseResourcePlanning",
        },
      ],
    },
    {
      title: "Specialized Services",
      links: [
        {
          title: "Event Coordination",
          link: "/blogs/SpecializedServices/Event Coordination",
        },
        {
          title: "The ARK Events",
          link: "/blogs/SpecializedServices/Team Building Events",
        },
        {
          title: "Startup Incubation",
          link: "/blogs/SpecializedServices/Startup Incubation",
        },
      ],
    },
  ];

  useEffect(() => {
    setNavOpened(open);
    let anim;
    if (open) {
      anim = lottie.loadAnimation({
        container: ExitIconRef.current,
        renderer: "svg", // You can choose 'canvas' or 'html' as well
        loop: false,
        autoplay: true,
        animationData: ExitIconData, // The JSON animation data
      });
    }
    return () => {
      if (anim) anim.destroy(); // Clean up animation when component unmounts
    };
  }, [open]);

  useEffect(() => {
    setNavOpened(navMobile);
  }, [navMobile]);

  const ExitIconRef = useRef(null);

  useEffect(() => {
    let anim = lottie.loadAnimation({
      container: ExitIconRef.current,
      renderer: "svg", // You can choose 'canvas' or 'html' as well
      loop: false,
      autoplay: true,
      animationData: ExitIconData, // The JSON animation data
    });
    return () => {
      anim.destroy(); // Clean up animation when component unmounts
    };
  }, []);

  return (
    <>
      {!inHome && (
        <div
          className={`fixed top-0 left-0 w-full z-40  ${
            open
              ? "bg-[rgba(0, 0, 0, 0.80)]  h-[80vh]"
              : "bg-[rgba(0, 0, 0, 0.80)] h-[100px]  md:h-[150px]"
          }  `}
        ></div>
      )}
      <div
        className={`transition-all duration-1000 ease-in-out  fixed flex flex-col z-40 top-0 left-0  w-full  px-8 ${
          open
            ? "bg-[#000000ce]  h-[100vh]"
            : " h-[120px]  md:h-[150px] bg-gradient-to-b from-[#000000af] to-[transparent]"
        }  `}
      >
        {!navMobile && (
          <>
            <div
              className={`pt-4 md:pt-0 text-[#ffffff] ${
                open ? "max-h-[15vh] " : "max-h-[15vh] "
              }`}
            >
              <div className="flex flex-row justify-between items-center h-[100%]">
                {/* <Link
              to={"https://goo.gl/maps/hofrdKh54EYEJdbU8"}
              className="w-[30%] flex hover:text-[#6a7868] items-center justify-center"
            >
              <CiLocationOn size={20} />
              <p className="ml-2 md:text-md text-sm">Beirut, Lebanon</p>
            </Link> */}
                <div className="flex h-[50%] w-[30%] justify-center mx-auto">
                  <Link to={`/`}>
                    <img
                      src={LogoWhite}
                      className={`h-[70%] scale-150 md:scale-100 object-contain transition-all duration-2000 ease-in-out `}
                      alt=""
                    />
                  </Link>
                </div>
                {open && (
                  <div
                    ref={ExitIconRef}
                    className="w-[50px] object-contain absolute top-[85px] right-5 cursor-pointer"
                    onClick={() => {
                      setOpen(false);
                      setHover("");
                    }}
                  ></div>
                )}
                {(!inHome || open) && (
                  <div className="w-[30%] flex justify-end absolute right-5 top-10">
                    <div className="md:flex hidden">
                      <Link to={`https://www.instagram.com/thearknetworks/`}>
                        <PiInstagramLogoLight
                          size={20}
                          className="mx-2 hover:text-[#6a7868]"
                        />
                      </Link>
                      <Link to={`https://www.facebook.com/TheARKNetworks`}>
                        <FaFacebookSquare
                          size={20}
                          className="mx-2 hover:text-[#6a7868]"
                        />
                      </Link>
                      <Link
                        to={`https://www.linkedin.com/company/the-ark-networks/`}
                      >
                        <BsLinkedin
                          size={20}
                          className="mx-2 hover:text-[#6a7868]"
                        />
                      </Link>
                      <Link
                        to={`https://x.com/thearknetworks?t=c_Rn8WmpPQFKCUhebq8Zew&s=08`}
                      >
                        <RiTwitterXFill
                          size={20}
                          className="mx-2 hover:text-[#6a7868]"
                        />
                      </Link>
                      <a href="tel:+96101355145">
                        <PiPhoneDisconnectFill
                          size={20}
                          className="mx-2 hover:text-[#6a7868]"
                        />
                      </a>
                      <a href="tel:+96178812651">
                        <BsPhoneVibrateFill
                          size={20}
                          className="mx-2 hover:text-[#6a7868]"
                        />
                      </a>
                      <a href="mailto:admin@thearknetworks.com">
                        <MdEmail
                          size={20}
                          className="mx-2 hover:text-[#6a7868]"
                        />
                      </a>
                    </div>
                  </div>
                )}{" "}
                <div
                  onClick={() => setNavMobile(!navMobile)}
                  className="block cursor-pointer md:hidden"
                >
                  {navMobile ? (
                    <AiOutlineClose size={20} />
                  ) : (
                    <AiOutlineMenu size={20} />
                  )}
                </div>
              </div>

              <div
                className={`md:flex md:flex-row hidden justify-center ${
                  inHome ? "text-white" : open ? "text-white" : "text-black"
                } `}
              >
                <ul className="flex flex-row justify-center">
                  <li
                    className={` ${
                      hover === "aboutus"
                        ? `px-4 border-b-[1.5px] border-[#ffffff]   mx-2 `
                        : `px-4 mx-2 border-b-[1.5px] border-[#00000000]`
                    }`}
                    onMouseEnter={() => {
                      setHover("aboutus");
                      setOpen(true);
                    }}
                  >
                    About Us
                  </li>
                  <li
                    className={`${
                      hover === "services"
                        ? `px-4 border-b-[1.5px] border-[#ffffff]   mx-2`
                        : `px-4 mx-2 `
                    }`}
                    onMouseEnter={() => {
                      setHover("services");
                      setOpen(true);
                    }}
                  >
                    Our Services
                  </li>
                  <li
                    className={`${
                      hover === "team"
                        ? `px-4 border-b-[1.5px] border-[#ffffff]   mx-2`
                        : `px-4 mx-2 `
                    }`}
                    onMouseEnter={() => {
                      setHover("team");
                      setOpen(true);
                    }}
                  >
                    Our Team
                  </li>
                  <li
                    className={`${
                      hover === "portfolio"
                        ? `px-4 border-b-[1.5px] border-[#ffffff]   mx-2`
                        : `px-4 mx-2 `
                    }`}
                    onMouseEnter={() => {
                      setHover("portfolio");
                      setOpen(true);
                    }}
                  >
                    Our Portfolio
                  </li>
                  <li
                    className={`${
                      hover === "blogs"
                        ? `px-4 border-b-[1.5px] border-[#ffffff]   mx-2`
                        : `px-4 mx-2 `
                    }`}
                    onMouseEnter={() => {
                      setHover("blogs");
                      setOpen(true);
                    }}
                  >
                    Blogs
                  </li>
                  <li
                    className={`${
                      hover === "contactus"
                        ? `px-4 border-b-[1.5px] border-[#ffffff]   mx-2`
                        : `px-4 mx-2 `
                    }`}
                    onMouseEnter={() => {
                      setHover("contactus");
                      setOpen(true);
                    }}
                  >
                    Contact Us
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`transition-all duration-1000 ease-in-out hidden md:flex pb-4  max-h-[80%]  ${
                open ? "w-full flex-1 mt-12 " : "hidden"
              } `}
            >
              {hover === "aboutus" && (
                <>
                  <Aboutus />
                </>
              )}
              {hover === "services" && (
                <>
                  <Services className={hover === "services" ? "" : "hidden"} />
                </>
              )}
              {hover === "blogs" && (
                <>
                  <Blogs className={hover === "blogs" ? "" : "hidden"} />
                </>
              )}
              {hover === "portfolio" && (
                <Portfolio className={hover === "portfolio" ? "" : "hidden"} />
              )}
              {hover === "team" && (
                <>
                  <Team className={hover === "team" ? "" : "hidden"} />
                </>
              )}
              {hover === "contactus" && (
                <>
                  <ContactUs className={hover === "team" ? "" : "hidden"} />
                </>
              )}
            </div>
          </>
        )}
        <div
          className={
            navMobile
              ? "fixed left-0 top-0  w-[100%] border-r border-r-gray-900 bg-[#000000bd] text-white pt-8 px-4 h-full overflow-y-auto transition-all ease-in-out duration-1000"
              : "fixed left-[-100%]  top-0 w-[100%] h-full bg-[#000000bd] transition-all ease-in-out duration-1000 pt-8 px-4"
          }
        >
          <>
            <Link to={`/`} className="flex justify-between">
              <img
                src={LogoWhite}
                alt=""
                className="w-[50%] object-contain md:w-[30%] ml-6 mt-6"
              />
              <img
                src={ExitIcon}
                alt="Exit"
                className="w-[50px] h-auto object-contain mb-[-10px]"
                onClick={() => setNavMobile(false)}
              />
            </Link>
            <ul className="uppercase p-4 ">
              <li
                className="p-4 border-b border-b-gray-600 "
                onClick={() => {
                  setHoverMobile("aboutus");
                  setOpen(true);
                }}
              >
                <Link to={"/about"}>About Us</Link>
              </li>
              <li
                className="p-4 border-b border-b-gray-600"
                onClick={() => {
                  if (hoverMobile === "services") {
                    setOpen(false);
                    setHoverMobile("");
                  } else {
                    setHoverMobile("services");
                  }
                }}
              >
                Our Services
                <ul
                  className={`transition-all duration-1000 ease-in-out    ${
                    hoverMobile === "services"
                      ? "w-full flex-1 scale-100 h-auto"
                      : "hidden scale-0 h-0"
                  } `}
                >
                  {services.map((service, index) => (
                    <li className="ml-2 mt-4 border-b-[1px] border-[#6a7868]">
                      <Link
                        to={`/services/${encodeURIComponent(
                          service.indicator
                        )}`}
                        onClick={() => {
                          setNavMobile(false);
                          setOpen(false);
                        }}
                        key={index}
                      >
                        {service.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="p-4 border-b border-b-gray-600 ">
                <Link to={"/team"}>Our Team</Link>
              </li>

              <li className="p-4 border-b border-b-gray-600">
                <div
                  onClick={() => {
                    if (hoverMobile === "portfolio") {
                      setOpen(false);
                      setHoverMobile("");
                    } else {
                      setHoverMobile("portfolio");
                    }
                  }}
                >
                  <p>Our Portfolio</p>
                </div>

                <ul
                  className={`transition-all duration-1000 ease-in-out    ${
                    hoverMobile === "portfolio"
                      ? "w-full flex-1 scale-100 h-auto"
                      : "hidden scale-0 h-0"
                  } `}
                >
                  {portfolio.map((port, index) => (
                    <li
                      className="ml-2 mt-4 border-b-[1px] border-[#6a7868]"
                      onClick={() => {
                        if (tempLinks.title === port.title) {
                          setTempLinks("");
                        } else {
                          setTempLinks(port);
                        }
                      }}
                    >
                      {port.title}

                      {
                        <ul
                          className={`transition-all duration-1000 ease-in-out    ${
                            tempLinks.title === port.title
                              ? "w-full flex-1 scale-100 h-auto"
                              : "hidden scale-0 h-0"
                          } `}
                        >
                          {tempLinks?.links?.map((link, index) => (
                            <li className={`ml-4 mt-4`}>
                              <Link
                                to={link.link}
                                onClick={() => {
                                  setNavMobile(false);
                                  setOpen(false);
                                }}
                                key={index}
                              >
                                {index + 1 + ". "}
                                {link.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      }
                    </li>
                  ))}
                </ul>
              </li>
              <li className="p-4 border-b border-b-gray-600">
                <div
                  onClick={() => {
                    if (hoverMobile === "blogs") {
                      setOpen(false);
                      setHoverMobile("");
                    } else {
                      setHoverMobile("blogs");
                    }
                  }}
                >
                  <p>Blogs</p>
                </div>

                <ul
                  className={`transition-all duration-1000 ease-in-out    ${
                    hoverMobile === "blogs"
                      ? "w-full flex-1 scale-100 h-auto"
                      : "hidden scale-0 h-0"
                  } `}
                >
                  {blogs.map((blogCategory, index) => (
                    <li
                      className="ml-2 mt-4 border-b-[1px] border-[#6a7868]"
                      onClick={() => {
                        if (tempLinks.title === blogCategory.title) {
                          setTempLinks("");
                        } else {
                          setTempLinks(blogCategory);
                        }
                      }}
                    >
                      {blogCategory.title}
                      <ul
                        className={`transition-all duration-1000 ease-in-out    ${
                          tempLinks.title === blogCategory.title
                            ? "w-full flex-1 scale-100 h-auto"
                            : "hidden scale-0 h-0"
                        } `}
                      >
                        {tempLinks?.links?.map((link, index) => (
                          <li className={`ml-4 mt-4`} key={index}>
                            <Link
                              to={link.link}
                              onClick={() => {
                                setNavMobile(false);
                                setOpen(false);
                              }}
                            >
                              {index + 1 + ". "}
                              {link.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>

              <li
                className="p-4 border-b border-b-gray-600 "
                onClick={() => {
                  if (hoverMobile === "contact") {
                    setOpen(false);
                    setHoverMobile("");
                  } else {
                    setHoverMobile("contact");
                  }
                }}
              >
                Contact Us
              </li>
              <div
                className={`transition-all duration-1000 ease-in-out ${
                  hoverMobile === "contact"
                    ? "flex flex-col  w-[100%] scale-100  justify-center px-4"
                    : "hidden scale-0 h-0"
                }`}
              >
                <div className="flex flex-col mt-4">
                  <input
                    style={{ outline: "none" }}
                    className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
                    value={first}
                    onChange={(e) => setFirst(e.target.value)}
                    placeholder="First Name"
                  />
                </div>

                <div className="flex flex-col mt-4">
                  <input
                    style={{ outline: "none" }}
                    className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
                    value={last}
                    onChange={(e) => setLast(e.target.value)}
                    placeholder="Last Name"
                  />

                  <div className="flex flex-col mt-4">
                    <input
                      style={{ outline: "none" }}
                      className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />

                    <div className="flex flex-col mt-4">
                      <input
                        style={{ outline: "none" }}
                        className="rounded-sm bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff]"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        placeholder="Phone Number"
                      />

                      <div className="flex flex-col mt-4">
                        <textarea
                          style={{ outline: "none" }}
                          className="rounded-sm p-0 h-auto bg-transparent border-b-2 border-white text-white placeholder:text-[#ffffff] border-x-0 border-t-0"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Message"
                        />
                      </div>
                      <div className="flex flex-row w-full justify-end">
                        <button
                          className="mt-8 bg-[transparent]  py-2 rounded-sm text-[#ffffff]"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </>
        </div>
      </div>
    </>
  );
};

export default Navbar;
